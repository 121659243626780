import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const fullwidthHeaders = document.querySelectorAll(
    '.fullwidth-header__carousel'
  );

  if (0 < fullwidthHeaders.length) {
    fullwidthHeaders.forEach(function (carousel) {
      const fullwidthHeaderSlides = carousel.querySelectorAll(
        '.fullwidth-header__carousel .wp-block-media-text'
      );

      fullwidthHeaderSlides.forEach(function (item) {
        item.classList.add('swiper-slide');
      });

      const slides = carousel.querySelectorAll('.swiper-slide');

      if (1 < slides.length) {
        carousel.classList.remove('single-slide');
        const totalSlides = slides.length;

        new Swiper(carousel, {
          direction: 'horizontal',
          loop: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: true,
          },
          initialSlide: Math.floor(Math.random() * totalSlides),
          pagination: {
            el: carousel.querySelector('.swiper-pagination'),
            clickable: true,
          },
        });
      }
    });
  }
});
